<template>
	<div style="padding: 20px;">
		<el-button type="primary" plain style="font-family: 'UKIJ Tuz Tom';font-size: 30px;" @click="onCancel">
			{{yuyan.fanhui}}
		</el-button>
		<el-row :gutter="20">
			<el-col :span="15">
				<el-card :style="{minHeight: '85%',textAlign:yuyan.now=='hanyu'?'left':'right'}" shadow="hover">

					<div class="qu-content">
						<!-- 单选 -->
						<!-- <p>【{{ quData.quType | quTypeFilter }}】{{ quData.content }}</p> -->
						<p :style="{direction: yuyan.now=='hanyu'?'ltr':'rtl',width: '100%',fontSize: '18px'}">
							{{ quData.content }}
						</p>
						<div v-if="quData.audio">
							<el-tag style="margin:10px;" type="success" v-if="!playing" @click="play(1)">Play👂
							</el-tag>
							<el-tag style="margin:10px;" type="warning" v-if="playing" @click="stop()">Stop🤚
							</el-tag>
							<audio ref="audio" autoplay
								:src="'https://admin.rolumapp.com/upload/'+quData.audio"></audio>
							<audio ref="audioAnalysis"
								:src="'https://admin.rolumapp.com/upload/'+quData.audioAnalysis"></audio>
						</div>
						<div v-if="quData.quType === 1 || quData.quType===3 ">
							<el-radio-group readonly>
								<el-radio v-if="quData.quType === 1" v-for="an in quData.answerList" :label="an.id"
									readonly
									:style="{textAlign: yuyan.now=='hanyu'?'left':'right',paddingLeft: '10px'}">
									<div style="font-size: 16px;padding: 15px 20px 0 20px;">
										<span style="margin-right: 30;">{{ an.abc }}</span> . {{ an.content }}
									</div>
									<div v-if="an.image!==''" style="display: flex;flex-wrap: wrap;">
										<el-image :preview-src-list="[imgShow(an.image)]"
											style="height: 150px;width: auto;" :src="imgShow(an.image)" fit="contain">
										</el-image>
									</div>
								</el-radio>
							</el-radio-group>
						</div>

						<!-- 多选题 -->
						<div v-if="quData.quType === 2">
							<el-radio-group readonly>
								<el-radio v-for="an in quData.answerList" :label="an.id"
									:style="{textAlign: yuyan.now=='hanyu'?'left':'right',paddingLeft: '10px'}">

									<!-- {{ an.abc }}.{{ an.content }} -->
									<div style="font-size: 18px;padding: 15px 20px 0 20px;">
										<span style="margin-right: 30;">{{ an.abc }}</span> . {{ an.content }}
									</div>
									<div v-if="an.image!==''" style="display: flex;flex-wrap: wrap;">
										<el-image :preview-src-list="[imgShow(an.image)]"
											style="height: 150px;width: auto;" :src="imgShow(an.image)" fit="contain">
										</el-image>
									</div>
								</el-radio>
							</el-radio-group>
						</div>

						<div v-if="analysisShow" style="margin-top: 20px; color: #1890ff; font-weight: bold">
							{{yuyan.jiaojuan_yes}}：{{ rightTags.join(' ') }}
						</div>

					</div>
					<div v-if="images.length!==0" style="margin-top: 30px;text-align: center;">
						<!-- ``````````````	{{images}}`````````````````````` -->
						<el-image style="min-height: 200px;width: auto;" v-for="item in images"
							:preview-src-list="images" :src="item" fit="fill">
							<template #error>
								<div class="image-slot">
								</div>
							</template>
						</el-image>
					</div>
				</el-card>
				<el-card style="min-height: 15%;" shadow="hover">
					<div style="display: flex;justify-content: space-between;">
						<div></div>
						<div style="display: flex;" v-if="bt_loading">
							<!-- 单选和判断 -->
							<div style="margin: 0 20px;font-size: 30px;font-weight: 900;"
								v-if="quData.quType === 1 || quData.quType===3 ">
								<el-radio-group @change="handNext" v-model="answerValues[0]" readonly
									style="display: inline-flex;">
									<el-radio v-for="an in quData.answerList" :label="an.id+''" readonly
										v-if="quData.quType === 1">
										<span
											style="line-height: 50px;font-size: 26px;padding-right: 9px;">{{ an.abc }}</span>
									</el-radio>
									<el-radio v-for="(an,index) in quData.answerList" :label="an.id+''" readonly
										v-if="quData.quType === 3">
										<span style="line-height: 50px;font-size: 26px;padding-right: 9px;"
											v-if="index === 0">✓</span>
										<span style="line-height: 50px;font-size: 26px;padding-right: 9px;"
											v-if="index === 1">✘</span>
									</el-radio>
								</el-radio-group>
							</div>
							<!-- 多选 -->
							<div style="margin: 0 20px;font-size: 30px;font-weight: 900;" v-if="quData.quType === 2">
								<el-checkbox-group v-model="answerValues" readonly style="display: inline-flex;">
									<el-checkbox v-for="an in quData.answerList" :label="an.id+''" readonly>
										<span
											style="line-height: 50px;font-size: 26px;padding-right: 9px;">{{ an.abc }}</span>
									</el-checkbox>
								</el-checkbox-group>
							</div>

						</div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="9">
				<el-card style="min-height: 100%;" shadow="hover">
					<div class="app-container">

						<el-row :gutter="24">
							<el-col :xs="24" style="margin-bottom: 10px">

								<el-card>
									<p class="card-title">{{yuyan.start_answer_card}}</p>
									<div>
										<el-row :gutter="2" class="card-line" style="padding-left: 10px">
											<el-tag type="info">{{yuyan.start_answerno_card}}</el-tag>
											<el-tag effect="dark" type="primary">{{yuyan.practice_datika1}}</el-tag>
											<el-tag effect="dark" type="danger">{{yuyan.practice_datika3}}</el-tag>
											<el-tag effect="dark" type="success">{{yuyan.practice_datika4}}</el-tag>
										</el-row>

										<div v-if="(paperData.radioList!==undefined && paperData.radioList.length > 0) || (paperData.judgeList!==undefined && paperData.judgeList.length > 0) || (paperData.multiList!==undefined && paperData.multiList.length > 0)"
											style="margin-top: 10px;">
											<!-- <p class="card-title">{{yuyan.start_answer_dan}}</p> -->
											<el-row :gutter="24" class="card-line">
												<div style="text-align: center;"
													v-for="(item,index) in practice_all_data">
													<span
														style="color: #909399;font-size: 12px;display: block;">{{ index + 1 }}</span>
													<el-tag :effect="!item.answered?'light':'dark'"
														style="width: 30px;text-align: center;"
														:id = "item.id"
														:type="cardItemClass(item.answered, item.id,item.isRight)"
														@click="handSave(item)">
														<span
															v-if="item.quType != 3">{{anserSaver[item.id]?anserSaver[item.id]:"-"}}</span>
														<span
															v-if="item.quType == 3">{{anserSaver[item.id]?anserSaver[item.id]=="A"?"✓":"✘":"-"}}</span>
													</el-tag>
												</div>
											</el-row>
										</div>
										<el-row>
											<el-col style="margin-top: 10px;">
												<el-card shadow="hover">
													<div style="display: flex;justify-content: space-between;">
														<div style="font-size: 16px;">{{yuyan.timuleixing}}
															({{quData.quType==1?yuyan.danxuan:(quData.quType==2?yuyan.duoxuan:yuyan.panduan)}})
														</div>
														<div>
															<el-button type="primary"
																style="font-family: 'UKIJ Tuz Tom';font-size: 16px;"
																@click="handUp" v-show="is_showup">{{yuyan.practice_up}}
															</el-button>
															<el-button type="primary"
																style="font-family: 'UKIJ Tuz Tom';font-size: 16px;"
																@click="handNext(quData.quType==2?'':'next')"
																v-show="is_showNext">{{yuyan.practice_next}}</el-button>
															<el-button type="primary"
																style="font-family: 'UKIJ Tuz Tom';font-size: 16px;"
																@click="handLast()"
																v-show="!is_showNext">{{yuyan.sumbit_last}}</el-button>
														</div>
													</div>
												</el-card>
											</el-col>
										</el-row>
									</div>
								</el-card>
							</el-col>
						</el-row>
					</div>
				</el-card>
			</el-col>
		</el-row>

		<el-dialog v-model="analysisShow" :title="yuyan.practice_title" width="60%" :before-close="cancelAnalysisShow" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
			<!-- <span style="font-size: 25px;">
						{{yuyan.practice_aideo}}
					</span> -->
			<div v-if="quData.audioAnalysis">
				<!-- <span style="font-size: 25px;">
							<p>{{yuyan.practice_aideo_click}} {{yuyan.practice_aideo}}</p>
						</span> -->
				<el-tag style="margin:10px;" type="success" v-if="!playing" @click="play(2)">Play👂</el-tag>
				<el-tag style="margin:10px;" type="warning" v-if="playing" @click="stop()">Stop🤚</el-tag>
			</div>
			<p>{{quData.analysis}}</p>
			<div style="margin-top: 20px; color: #1890ff; font-weight: bold">
				{{yuyan.jiaojuan_yes}} : {{ quData.quType==3?(rightTags=="A"?"✓":"✘"):rightTags.join(" ")}}
			</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="cancelAnalysisShow()" v-if="!is_showNext">{{yuyan.quxiao}}</el-button>
					<el-button type="primary" @click="handNext" v-if="is_showNext">{{yuyan.practice_next}}</el-button>
				</span>
			</template>
		</el-dialog>

	</div>

</template>

<script>
	import {
		fetchDetail,
		nextQu,
		praceDetail
	} from '@/api/lianxi'
	export default {
		name: 'BookTrain',
		data() {
			return {
				// 考试图片
				images: [],
				// 是否显示下一个
				is_showNext: true,
				// 是否显示上一个
				is_showup: true,
				// 当前是哪个题
				current: {
					type: '单选',
					index: 1
				},
				// 总数居
				paperData: {},
				// 题目总数居
				practice_all_data: [],
				// 当前做的数据
				current_practice: {},
				// 语言
				yuyan: this.$language(),
				// 考试id
				examId: '',
				// 题目id
				quId: '',
				// 答题字幕编号
				tags: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N'],
				// 解释文字开关
				analysisShow: false,
				// 问题数据
				quData: {},
				// 答案列表
				answerValues: [],
				// 判断答案列表
				rightValues: [],
				// 判断标识
				rightTags: [],
				// 播放器
				player: null,
				// 是否在播放,默认是
				playing: true,
				// 答案保存器
				anserSaver: {},
				// 等待效果
				bt_loading:false
			}
		},
		created() {
			let token = sessionStorage.getItem("token")
			let currentMenu = sessionStorage.getItem("currentMenu")
			let language = sessionStorage.getItem("language")
			let userInfo = sessionStorage.getItem("userInfo")
			sessionStorage.clear()
			sessionStorage.setItem("token",token)
			sessionStorage.setItem("currentMenu",currentMenu)
			sessionStorage.setItem("language",language)
			sessionStorage.setItem("userInfo",userInfo)
			this.yuyan = this.$language()
			this.examId = this.$route.query.examId
			this.getPraceData()
			// 首先初始化为读题目
			this.player = this.$refs.audio
		},
		watch: {
			'$store.state.language_num'(to, from) {
				this.yuyan = this.$language()
			},
			'current.index'(to, from) {
				this.fetchQuDetail(this.current.id)
			}
		},
		
		methods: {
			// 显示图片
			imgShow(url) {
				var r = new RegExp(/http/g); //构造正则表达式对象
				if (r.test(url))
					return url
				else
					return 'https://admin.rolumapp.com/upload/' + url
			},

			// 按类型播放，1为朗读题目，2为朗读解释
			play(type) {
				if (type == 1) {
					this.plarer = this.$refs.audio
				}
				if (type == 2) {
					this.plarer = this.$refs.audioAnalysis
				}
				if (this.plarer) {
					this.plarer.play()
					this.playing = true
				}
			},

			// 停止所有语音
			stop(type) {
				this.plarer = this.$refs.audio
				if (this.plarer) {
					this.plarer.pause()
				}
				this.plarer = this.$refs.audioAnalysis
				if (this.plarer) {
					this.plarer.pause()
				}
				this.player == null
				this.playing = false
			},

			// 点击序号获取答案
			handSave(e) {
				this.is_showNext = true
				this.current = e
				if (e.index == this.practice_all_data.length && e.answered) {
					this.analysisShow = true
					this.is_showNext = false
				}
				this.stop()
				this.play(1)
			},

			// 获取练习所有数据
			getPraceData() {
				praceDetail({
					id: this.examId
				}).then(res => {
					this.paperData = res
					let changdu = 0
					// 判断
					if (this.paperData.judgeList !== undefined && this.paperData.judgeList.length > 0) {
						changdu = changdu + 1
						this.paperData.judgeList.forEach((data, index) => {
							data.index = index + 1
							this.practice_all_data.push(data)
						})
						changdu = this.practice_all_data.length
					}
					// 单选题 
					if (this.paperData.radioList !== undefined && this.paperData.radioList.length > 0) {
						changdu = changdu + 1
						this.paperData.radioList.forEach((data, index) => {
							data.index = changdu + index
							this.practice_all_data.push(data)
						})
						changdu = this.practice_all_data.length
					}
					// 多选
					if (this.paperData.multiList !== undefined && this.paperData.multiList.length > 0) {
						changdu = changdu + 1
						this.paperData.multiList.forEach((data, index) => {
							data.index = changdu + index
							this.practice_all_data.push(data)
						})
						changdu = this.practice_all_data.length
					}
					// 当前是第一道题
					this.current = this.practice_all_data[0]
					// 当前问题的详情
					this.fetchQuDetail(this.practice_all_data[0].id)
				})
			},

			// 按照答案显示不同的演示
			cardItemClass(answered, id, isRight) {
				
				if (id === this.current.id) {
					return 'el-tag el-tag--primary el-tag--dark'
				}

				if (isRight == "dui" || isRight == "cuo") {
					if (sessionStorage.getItem(id)) {
						return sessionStorage.getItem(id)
					} else {
						if (isRight == "dui") {
							sessionStorage.setItem(id, 'success')
							return 'success'
						}

						if (isRight == "cuo") {
							sessionStorage.setItem(id, 'danger')
							return 'danger'
						}
					}
				}


				if (!answered) {
					return 'info'
				}
			},
			// 清理值
			clearValues() {
				if (!this.current.answered) {
					this.answerValues = []
				}
				this.rightValues = []
				this.analysisShow = false
				this.rightTags = []
			},

			// 查找试卷详情
			fetchQuDetail(id) {

				// 当前赋值
				this.quId = id
				// 情况所有数据
				this.clearValues()
				// 获取题目详情
				fetchDetail(id).then(response => {
					this.bt_loading = true
					// 题目图片列表
					this.images = []
					// 题目信息
					this.quData = response
					// 如果图片存在的话，进行分割以及显示结果
					if (response.images) {
						this.images = response.images.split("%")
						this.images.forEach((item, index) => {
							var r = new RegExp(/http/g); //构造正则表达式对象
							if (r.test(item)) {} else {
								this.images[index] = 'https://admin.rolumapp.com/upload/' + item
							}
						})
					}
					// 保存正确答案
					this.quData.answerList.forEach((an, index) => {
						an.abc = this.tags[index]

						// 用户选定的
						if (an.isRight) {
							this.rightValues.push(an.id)
							this.rightTags.push(an.abc)
						}
					})
				})
				this.isWite()
			},
			// 返回首页
			onCancel() {
				this.$router.push({
					name: '首页'
				})
			},
			// 做完一道题执行一次
			isWite() {
				// 最后一个不显示下一题
				if (this.practice_all_data.length == this.current.index) {
					this.is_showNext = false
				}
				if (this.practice_all_data[this.current.index - 1].answered == true) {
					this.answerValues = this.practice_all_data[(this.current.index - 1)].answeresult
				}
			},
			// 上一道题
			handUp() {
				this.is_showNext = true
				if (this.current.index > 1) {
					if (this.current.isRight == "dui")
						this.analysisShow = false
					else {
						this.analysisShow = true
					}
					this.current = this.practice_all_data[(this.current.index - 2)]
				} else {
					this.$message({
						message: '已经到头了！',
						type: 'error'
					})
				}
			},
			// 取消解释窗口
			cancelAnalysisShow() {
				this.analysisShow = false
				this.stop()
			},
			// 下一道题
			handNext(type) {
				this.bt_loading = false
				// 直接跳过去
				if (type == "next") {
					this.current = this.practice_all_data[this.current.index]
					return
				}
				// 直接显示下一个
				if (!this.answerValues.length == 0) {
					this.practice_all_data[this.current.index - 1].answeresult = []
					this.practice_all_data[this.current.index - 1].answeresult = this.answerValues

					this.practice_all_data[this.current.index - 1].answered = true
					if (this.rightValues.sort().join(',') === this.answerValues.sort().join(',')) {
						this.practice_all_data[this.current.index - 1].isRight = "dui"
					} else {
						this.practice_all_data[this.current.index - 1].isRight = 'cuo'
					}
				}
				if (this.analysisShow) {
					// 正确显示下一个
					this.current = this.practice_all_data[this.current.index]
					// this.fetchNextQu()
				} else {
					// 保存用户选的答案
					let str = "";
					this.quData.answerList.forEach((item, index) => {
						this.answerValues.forEach((item_in, index_in) => {
							if (item.id == item_in) {
								str = str + this.tags[index]
							}
						})
					})
					this.anserSaver[this.quData.id] = str
					
					// 直接判断正确性
					if (this.rightValues.sort().join(',') == this.answerValues.sort().join(',')) {
						this.$message({
							message: '回答正确，你好棒哦！',
							type: 'success'
						})
						// 如果还有下一道题显示相关按钮
						if (this.current.index < this.practice_all_data.length) {
							this.is_showNext = true
							this.current = this.practice_all_data[this.current.index]
						} else {
							this.is_showNext = false
							this.analysisShow = true
						}
					} else {
						// 如果还有下一道题显示相关按钮
						if (this.current.index < this.practice_all_data.length) {
							this.is_showNext = true
						} else {
							this.is_showNext = false
						}
						// 错误显示解析
						this.analysisShow = true
						this.stop()
						this.play(2)
					}
				}
			},
			handLast(){
				// 直接显示下一个
				if (!this.answerValues.length == 0) {
					this.practice_all_data[this.current.index - 1].answeresult = []
					this.practice_all_data[this.current.index - 1].answeresult = this.answerValues

					this.practice_all_data[this.current.index - 1].answered = true
					if (this.rightValues.sort().join(',') === this.answerValues.sort().join(',')) {
						this.practice_all_data[this.current.index - 1].isRight = "dui"
					} else {
						this.practice_all_data[this.current.index - 1].isRight = 'cuo'
					}
				}
				if (this.analysisShow) {
					// 正确显示下一个
					this.current = this.practice_all_data[this.current.index]
					// this.fetchNextQu()
				} else {
					// 保存用户选的答案
					let str = "";
					this.quData.answerList.forEach((item, index) => {
						this.answerValues.forEach((item_in, index_in) => {
							if (item.id == item_in) {
								str = str + this.tags[index]
							}
						})
					})
					this.anserSaver[this.quData.id] = str
					// 直接判断正确性
					if (this.rightValues.sort().join(',') == this.answerValues.sort().join(',')) {
						this.$message({
							message: '回答正确，你好棒哦！',
							type: 'success'
						})
					} else {
						// 错误显示解析
						this.analysisShow = true
						this.stop()
						this.play(2)
					}
				}
			},
			handNextMult() {
				console.log(this.rightValues.join(','), this.answerValues.join(','))
			}
		}
	}
</script>

<style scoped>
	/*去掉radio的小圆点*/
	/deep/ .el-radio__input {
		display: none !important;
	}

	/*去掉radio的小圆点*/
	/deep/ .el-checkbox__input {
		display: none !important;
	}

	.zidingyi {
		display: flex;
	}

	.left {
		flex: 5;
	}

	.right {
		margin-left: 10px;
		flex: 2;
	}

	.qu-content div {
		line-height: 30px;
	}

	.qu-analysis p {
		color: #555;
		font-size: 14px
	}

	.qu-analysis-line {
		margin-top: 20px;
		border-bottom: #eee 1px solid
	}

	.el-checkbox-group label,
	.el-radio-group label {
		width: 100%;
		direction: rtl;
	}

	.el-radio-group {
		width: 100%;
	}
</style>

<style scoped lang="scss">
	.el-image /deep/ img {
		width: auto;
		margin: 20px;
	}

	.qu-content div {
		line-height: 30px;
	}

	.el-checkbox-group label,
	.el-radio-group label {
		width: 100%;
	}

	.card-title {
		background: #eee;
		line-height: 35px;
		text-align: center;
		font-size: 14px;
	}

	.card-line {
		padding-left: 10px
	}

	.card-line span {
		cursor: pointer;
		margin: 2px;
	}

	/deep/ .el-radio,
	.el-checkbox {
		border-radius: 4px;
		border: 1px solid #dcdfe6;
		margin-bottom: 10px;
	}

	.is-checked {
		border: #409eff 1px solid;
	}

	.el-radio img,
	.el-checkbox img {
		max-width: 200px;
		max-height: 200px;
		border: #dcdfe6 1px dotted;
	}
</style>
